export const animationProcess = [
	{
		title: 'scenariusz',
		list: [
			{
				title: '1. Kick off i konsultacje',
				text: 'Rozpoczynamy projekt! Zaczynamy od pomocy w konkretnym ustaleniu potrzeb i przeznaczenia animacji oraz zbieramy wszystkie informacje, które będą nam niezbędne, aby móc określić metodykę działań podczas tworzenia animacji. Omawiamy możliwości i w razie potrzeby również ograniczenia technologiczne.'
			},
			{
				title: '2. Treść lektorska',
				text: 'Przygotowujemy treść lektorską, która w następnych krokach zostanie nagrana przez profesjonalnego lektora (voiceover).'
			},
			{
				title: '3. Research i mood board',
				text: 'Na tym etapie analizujemy to, jakie formy stylistyczne byłyby naszym zdaniem najlepsze dla Twojego projektu. Przygotowujemy tablicę proponowanych przez nas stylów i klimatów, dzięki czemu będziemy mieć ogólny podgląd na to, do jakiego efektu będziemy dążyć.'
			},
			{
				title: '4. Storyboard',
				text: 'Dzielimy przygotowaną wcześniej i zaakceptowaną treść lektorską na fragmenty i rozrysowujemy do nich sceny. Rolą storyboardu jest wizualne nakreślenie ciągu historii przy jednoczesnym dopasowaniu go do treści lektorskiej, tak aby mieć pewność, że animacja atrakcyjnie dopełni treść, a nie ją przytłoczy. Dzięki storyboardowi wszyscy jesteśmy w stanie upewnić się, na czym polega nasza wizja i czy na pewno jest spójna.'
			}
		]
	},

	{
		title: 'ilustracje',
		list: [
			{
				title: '1. Styleframe\'y',
				text: 'Wybieramy dwie sceny ze storyboardu i tworzymy do nich ilustracje, dzięki którym zdefiniujemy kierunek artystyczny pozostałych ilustracji.\n' +
					'Podczas tworzenia styleframe\'ów opieramy się na stylu bądź stylach, jakie zostały wybrane na etapie mood boardu.'
			},
			{
				title: '2. Designboard',
				text: 'Tworzymy wszystkie pozostałe ilustracje do storyboardu.'
			},
			{
				title: '3. Przygotowanie techniczne do animacji',
				text: 'Po zaakceptowaniu wszystkich ilustracji potrzebujemy jeszcze trochę czasu na przygotowanie plików do animacji. Jest to związane z kwestiami technicznymi: ilustracje, które oglądasz, wyglądają trochę inaczej od strony zaplecza technicznego, inaczej nie bylibyśmy w stanie wprawić ich w ruch.'
			},

		]
	},

	{
		title: 'Nagrania lektorskie',
		list: [
			{
				title: 'Voiceover',
				text: 'Wspólnie wybieramy lektora i na podstawie zaakceptowanej treści nagrywamy ścieżkę dźwiękową.'
			}
		]
	},

	{
		title: 'animacja',
		list: [
			{
				title: '1. Animatik',
				text: 'W przypadku skomplikowanych projektów przed rozpoczęciem animacji tworzymy animatik, czyli nagranie lektorskie, do którego podkładamy nieruchome sceny ze storyboardu. Pozwala nam to na ocenienie, czy timing będzie zgodny z tym, co ma dziać się na ekranie.'
			},
			{
				title: '2. Animowanie',
				text: 'Na bazie storyboardu wprawiamy nasze statyczne ilustracje w ruch!'
			},
			{
				title: '3. Udźwiękowienie',
				text: 'To ostatni etap produkcji - podkładamy muzykę i efekty dźwiękowe pod gotową animację. Dzięki temu nabiera ona większej dynamiki i staje się jeszcze bardziej interesująca!'
			},
		]
	},

	{
		title: 'Gotowy produkt',
		list: [
			{
				title: 'Dostarczenie plików',
				text: 'Po akceptacji animacji dostarczamy finalne pliki z animacją, które będziesz mógł pobrać.'
			}
		]
	}
]
