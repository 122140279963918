export const animationENProcess = [
	{
		title: 'Scenario',
		list: [
			{
				title: '1. Kick-off and consultations',
				text: 'We start the project! We begin by helping identify the needs and purpose of the animation. Then, we collect all the information necessary to set the methodology of work when creating the video. Finally, we discuss the possibilities and, if essential, also technological solutions.'
			},
			{
				title: '2. Script',
				text: 'We prepare the script, which a professional voice actor will record in a future step.'
			},
			{
				title: '3. Research & Mood board',
				text: 'At this stage, we analyse what stylistic solutions would be the best fit for your project, in our opinion. Then, we prepare a set of our proposed styles and mood, thanks to which we will have a general overview of what effect we are going to achieve.'
			},
			{
				title: '4. Storyboard',
				text: 'We cut the previously prepared and approved voice-over script into fragments and then sketch the scenes for them. The role of the storyboard is to visually outline the story sequence while adapting it to the content, so we can be sure that the visuals will attractively complement the narration without overwhelming the message. Thanks to the storyboard, we can all determine what our vision is and whether it matches up.'
			}
		]
	},

	{
		title: 'Illustrations',
		list: [
			{
				title: '1. Style frames',
				text: 'We choose up to two scenes from the storyboard and draw full illustrations based on them: it helps us define and set the artistic direction of the remaining images. When creating style frames, we rely on the style and mood selected at the mood board stage.'
			},
			{
				title: '2. Designboard',
				text: 'We create all other illustrations based on the storyboard and art direction of approved style frames.'
			},
			{
				title: '3. Technical preparation of files',
				text: 'Once all the illustrations are approved, we still need more time to prepare these files for animation. It\'s related to technical issues: the pictures you see look slightly different on the technical side; otherwise, we wouldn\'t be able to put them in motion.'
			},

		]
	},

	{
		title: 'Voice acting',
		list: [
			{
				title: 'Voice-over',
				text: 'Together, we choose a voice actor and record a voice-over based on the approved script.'
			}
		]
	},

	{
		title: 'Animation',
		list: [
			{
				title: '1. Animatic',
				text: 'For complex projects, before starting the animation, we create an animatic. We put static scenes of the storyboard together with the voice-over. It allows us to define whether the timing will be consistent with what is happening on the screen.'
			},
			{
				title: '2. Animation',
				text: 'We put our static illustrations in motion!'
			},
			{
				title: '3. Sound design',
				text: 'This is the last stage of production - we put music and sound effects for the finished animation. Thanks to this, your video becomes even more dynamic and exciting!'
			},
		]
	},

	{
		title: 'Final product',
		list: [
			{
				title: 'File delivery',
				text: 'Once the animation is approved, we provide the final files that you can download.'
			}
		]
	}
]
