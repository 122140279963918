<template>
	<section class="a-process">
		<div class="a-process__container">
			<div class="a-process__content">
				<section class="scenario step-two">
					<section class="top-col-left">
						<div class="a-process-default">
							<span class="p-number-style">02</span>
							<h2 class="p-h2-style p-h2-sm-style">{{ data[0].title }}</h2>
						</div>

						<img v-if="this.$store.state.isMobile"
						     src="@/assets/images/animation/Animacja_proces/02_postacie.webp" alt="postacie"
						     class="a-process-mobile-image"
						>

						<div class="top-col-left-steps">
							<div class="a-process__step">
								<h3 class="h3-style">{{ data[0].list[0].title }}</h3>
								<p class="p-p-style">{{ data[0].list[0].text }}</p>
							</div>
							<div class="a-process__step">
								<h3 class="h3-style">{{ data[0].list[1].title }}</h3>
								<p class="p-p-style">{{ data[0].list[1].text }}</p>
							</div>
						</div>

						<div
							class="a-process-image-left"
							v-if="!this.$store.state.isMobile"
						>
							<img src="@/assets/images/animation/Animacja_proces/03_storyboard.webp" alt="storyboard">
						</div>
					</section>

					<section class="top-col-right">
						<div
							class="a-process-image-right"
							v-if="!this.$store.state.isMobile"
						>
							<img
								src="@/assets/images/animation/Animacja_proces/02_postacie.webp" alt="postacie"
							>
						</div>

						<div class="top-col-right-steps">
							<div class="a-process__step">
								<h3 class="h3-style">{{ data[0].list[2].title }}</h3>
								<p class="p-p-style">{{ data[0].list[2].text }}</p>
							</div>

							<img
								v-if="this.$store.state.isMobile"
								src="@/assets/images/animation/Animacja_proces/03_storyboard.webp" alt="storyboard"
								class="a-process-mobile-image"
							>

							<div class="a-process__step">
								<h3 class="h3-style">{{ data[0].list[3].title }}</h3>
								<p class="p-p-style">{{ data[0].list[3].text }}</p>
							</div>
						</div>
					</section>
				</section>

				<section class="illustration step-three">
					<div class="a-process-default --margin-left-120	">
						<span class="p-number-style">03</span>
						<h2 class="p-h2-style p-h2-sm-style">{{ data[1].title }}</h2>
					</div>

					<div class="illustration__content">
						<div class="a-process-image-left">
							<img src="@/assets/images/animation/Animacja_proces/04_styleframe1.webp"
							     alt="styleframe">
						</div>

						<div class="col-right">
							<div class="a-process__step">
								<h3 class="h3-style">{{ data[1].list[0].title }}</h3>
								<p class="p-p-style">{{ data[1].list[0].text }}</p>
							</div>
							<div class="a-process__step">
								<h3 class="h3-style">{{ data[1].list[1].title }}</h3>
								<p class="p-p-style">{{ data[1].list[1].text }}</p>
							</div>
						</div>
					</div>
					<div class="a-process-image-right">
						<img v-if="!this.$store.state.isMobile"
						     src="@/assets/images/animation/Animacja_proces/05_styleframe2.webp" alt="styleframe 2">
						<img v-else src="@/assets/images/animation/Animacja_proces/img_mobile.png"
						     alt="styleframe 2">
					</div>

					<div class="a-process__step step--margin-400">
						<h3 class="h3-style">{{ data[1].list[2].title }}</h3>
						<p class="p-p-style">{{ data[1].list[2].text }}</p>
					</div>
				</section>

				<section class="lector step-four">
					<div class="a-process-default --margin-left-120	 --fix-position">
						<span class="p-number-style">04</span>
						<h2 class="p-h2-style p-h2-sm-style">{{ data[2].title }}</h2>
					</div>

					<div class="a-process__step">
						<h3 class="h3-style">{{ data[2].list[0].title }}</h3>
						<p class="p-p-style">{{ data[2].list[0].text }}</p>
					</div>
				</section>

				<section class="animation-step step-five">
					<div class="a-process-default --margin-left-120">
						<span class="p-number-style">05</span>
						<h2 class="p-h2-style p-h2-sm-style">{{ data[3].title }}</h2>
					</div>

					<div class="animation-step__content">
						<div class="col-left">
							<div class="a-process__step">
								<h3 class="h3-style">{{ data[3].list[0].title }}</h3>
								<p class="p-p-style">{{ data[3].list[0].text }}</p>
							</div>
							<div class="a-process__step">
								<h3 class="h3-style">{{ data[3].list[1].title }}</h3>
								<p class="p-p-style">{{ data[3].list[1].text }}</p>
							</div>
							<div class="a-process__step">
								<h3 class="h3-style">{{ data[3].list[2].title }}</h3>
								<p class="p-p-style">{{ data[3].list[2].text }}</p>
							</div>
						</div>

						<div class="a-process-image-right">
							<img src="@/assets/images/animation/Animacja_proces/06_animacja.webp"
							     alt="animacja">
						</div>
					</div>
				</section>

				<section class="final step-six">
					<div class="a-process-default --margin-left-120	 --fix-position-6">
						<span class="p-number-style">06</span>
						<h2 class="p-h2-style p-h2-sm-style">{{ data[4].title }}</h2>
					</div>

					<div class="a-process__step">
						<h3 class="h3-style">{{ data[4].list[0].title }}</h3>
						<p class="p-p-style">{{ data[4].list[0].text }}</p>
					</div>
				</section>
			</div>
		</div>
	</section>
</template>

<script>
import {animationProcess} from '@/data/animationProcess'
import {animationENProcess} from '@/data/en/animationProcess'

export default {
	name: "index animation process",
	data() {
		return {
			animationProcess,
			animationENProcess
		}
	},
	computed: {
		data () {
			return this.$store.state.isEn ? animationENProcess : animationProcess
		}
	}
}
</script>

<style src="./style.sass" scoped lang="sass"></style>

<style lang="sass">
.a-process-01
	position: relative
	margin-top: 205px
	margin-left: 631px
	width: 595px

	@include view('md')
		padding: 0
		width: 100%
		margin: 0
		top: 30%
		left: 7%

		h2
			font-size: 29px

	@include view('sm')
		width: 100%
		margin: 50px 0 0 0
		padding: 0
		left: 0

		h2
			font-size: 29px
			margin-bottom: 50px

	p
		padding: 0 180px 0 0

		@include view('md')
			padding: 0 150px 0 0
			font-size: 13px
			line-height: 20px

		@include view('sm')
			padding: 0
			font-size: 13px
			line-height: 20px

	span
		position: absolute
		top: -90%
		left: -25%

		@include view('md')
			font-size: 120px
			top: -80%
			left: 0

		@include view('sm')
			font-size: 67px
			top: -50%
			left: 0
</style>
